import React, { useContext, useState } from "react";
import Header from "./components/Header";
import { AuthContext } from "./App";

import About from "./components/About";
import App3 from "./jhold/OrderSelect";
import App from "./jhold/OrderList";
import MakeOrder from "./jhold/MakeOrder";
import SampleCode from "./SampleCode";
import Argument from "./argument/Argument";
import ArgumentPkg from "./argumentpkg/ArgumentPkg";
import StandardLink from "./standard/StandardLink";
import StandardAdd from "./standard/StandardAdd";
import SampleEdit from "./components/SampleEdit";
import SampleEditeJh from "./components/SampleEditeJh";
import SampleList from "./components/SampleList";
import SampleList2 from "./components/SampleList2";
import SampleList3 from "./sample/SampleList3";
import SampleDetail from "./components/SampleDetail";
import CustomerForm from "./customer/CustomerForm";
import CustomerSelect from "./customer/CustomerSelect";
import CustomerList from "./customer/CustomerList";
import ProjectName from "./customer/ProjectName";
import ExtraInfoModel from "./components/ExtraInfoModel";
import ArgumentLinkStandard from "./components/ArgumentLinkStandard";
import SampleCodeFind from "./components/SampleCodeFind";
import SampleBlank from "./components/SampleBlank";
//import ArgumentPkgEdite from "./argumentpkg/ArgumentPkgName"
import ArgumentPkgNameJh from "./argumentpkg/ArgumentPkgNameJh";
import SampleListFilterByReport from "./sample/SampleListFilterByReport";
import SendReportAdd from "./sample/SendReportAdd";
import SendReportList from "./sample/SendReportList";
import AnalysisReport from "./customer/AanalysisReport";
import SampleUpdateSampleCode from "./sample/SampleUpdateSampleCode";
import PriceVer from "./argument/PriceVer";
import StandardList from "./standard/StandardList";
import StandardSelect from "./standard/StandardSelect";
import SampleExport from "./components/SampleExport";
import RepayList from "./components/RepayList";
//import StandardAdd from "./standard/StandardAdd";
import JholdSelect from "./jhold/JholdSelect";
import ProductApp from "./components/ProductApp";
import "./Home.css";

import { Switch, Route, Link } from "react-router-dom";


export const Home = () => {
  const { state } = useContext(AuthContext);
  const a = new Array(27).fill(false); //初始状态
  const [b, setB] = useState([
    ...new Array(16).fill(false),
    true,
    ...new Array(10).fill(false),
  ]);

  const selectLink = (index) => {
    let temp = a;
    temp[index] = true;
    setB(temp);
  };

  return (
    <div className="wrapper">
      <div className="box header">
        <Header />
      </div>
      <div className="box sidebar">
        <ul>
          <li
            onClick={() => {
              selectLink(0);
            }}
          >
            <Link to="/about" className={`${b[0] ? "active" : ""}`}>
              公告
            </Link>
          </li>

          <li
            onClick={() => {
              selectLink(1);
            }}
          >
            <Link to="/samplelist2" className={`${b[1] ? "active" : ""}`}>
              1.主查询
            </Link>
          </li>

          <li
            onClick={() => {
              selectLink(2);
            }}
          >
            <Link to="/samplelist" className={`${b[2] ? "active" : ""}`}>
              1.1收样列表
            </Link>
          </li>

          <li
            onClick={() => {
              selectLink(3);
            }}
          >
            <Link
              to={{
                pathname: `/samplejh/null`,
                operate: "add",
              }}
              className={`${b[3] ? "active" : ""}`}
            >
              2.收样
            </Link>
          </li>

          <li
            onClick={() => {
              selectLink(4);
            }}
          >
            <Link className={`${b[4] ? "active" : ""}`} to="/projectname">
              3.工程名称
            </Link>
          </li>
          <li
            onClick={() => {
              selectLink(5);
            }}
          >
            <Link to="/customerselect" className={`${b[5] ? "active" : ""}`}>
              3.1委托单位(查改)
            </Link>
          </li>
          <li
            onClick={() => {
              selectLink(6);
            }}
          >
            <Link to="/customerlist" className={`${b[6] ? "active" : ""}`}>
              3.2委托单位列表
            </Link>
          </li>

          <li
            onClick={() => {
              selectLink(7);
            }}
          >
            <Link to="/argument" className={`${b[7] ? "active" : ""}`}>
              4.参数
            </Link>
          </li>
          <li
            onClick={() => {
              selectLink(8);
            }}
          >
            <Link to="/argumentpkgedite" className={`${b[8] ? "active" : ""}`}>
              4.1.参数包维护
            </Link>
          </li>

          <li
            onClick={() => {
              selectLink(9);
            }}
          >
            <Link to="/standardlink" className={`${b[9] ? "active" : ""}`}>
              5.类目挂标准
            </Link>
          </li>
          <li
            onClick={() => {
              selectLink(10);
            }}
          >
            <Link to="/standardselect" className={`${b[10] ? "active" : ""}`}>
              5.1查询标准
            </Link>
          </li>
          <li
            onClick={() => {
              selectLink(11);
            }}
          >
            <Link to="/standardlist" className={`${b[11] ? "active" : ""}`}>
              5.2标准列表
            </Link>
          </li>
          <li
            onClick={() => {
              selectLink(12);
            }}
          >
            <Link to="/addstandard" className={`${b[12] ? "active" : ""}`}>
              5.3新增标准
            </Link>
          </li>
          <li
            onClick={() => {
              selectLink(13);
            }}
          >
            <Link to="/sampleexport" className={`${b[13] ? "active" : ""}`}>
              6.导出账目
            </Link>
          </li>

          <li
            onClick={() => {
              selectLink(27);
            }}
          >
            <Link to="/repaylist" className={`${b[27] ? "active" : ""}`}>
              6.1.日账单结算
            </Link>
          </li>

          <li
            onClick={() => {
              selectLink(14);
            }}
          >
            <Link to="/exactinfomodel" className={`${b[14] ? "active" : ""}`}>
              7.附加信息
            </Link>
          </li>

          <li
            onClick={() => {
              selectLink(15);
            }}
          >
            <Link to="/" className={`${b[15] ? "active" : ""}`}>
              8.待发报告
            </Link>
          </li>
          <li
            onClick={() => {
              selectLink(16);
            }}
          >
            <Link to="/samplelist3" className={`${b[16] ? "active" : ""}`}>
              8.1发任务发报告
            </Link>
          </li>
          <li
            onClick={() => {
              selectLink(17);
            }}
          >
            <Link to="/sendreportlist" className={`${b[17] ? "active" : ""}`}>
              8.2发放记录
            </Link>
          </li>

          <li
            onClick={() => {
              selectLink(18);
            }}
          >
            <Link to="/sampleblank" className={`${b[18] ? "active" : ""}`}>
              9.留号(占号)
            </Link>
          </li>

          <li
            onClick={() => {
              selectLink(19);
            }}
          >
            <Link to="/pricever" className={`${b[19] ? "active" : ""}`}>
              10.价格版本
            </Link>
          </li>
          <li
            onClick={() => {
              selectLink(20);
            }}
          >
            <Link to="/samplecode" className={`${b[20] ? "active" : ""}`}>
              11.样品代码
            </Link>
          </li>
          <li
            onClick={() => {
              selectLink(21);
            }}
          >
            <Link to="/samplecodefind" className={`${b[21] ? "active" : ""}`}>
              11.1样品编码查找
            </Link>
          </li>

          <li
            onClick={() => {
              selectLink(22);
            }}
          >
            <Link to="/argumentlink" className={`${b[22] ? "active" : ""}`}>
              12.按参数挂标准
            </Link>
          </li>

          <li
            onClick={() => {
              selectLink(23);
            }}
          >
            <Link to="/analysis" className={`${b[23] ? "active" : ""}`}>
              13.数据分析
            </Link>
          </li>

          <li
            onClick={() => {
              selectLink(24);
            }}
          >
            <Link
              to="/list2019bytrustname"
              className={`${b[23] ? "active" : ""}`}
            >
              14.1查委托人2019
            </Link>
          </li>
          <li
            onClick={() => {
              selectLink(25);
            }}
          >
            <Link to="/select" className={`${b[25] ? "active" : ""}`}>
              14.2查单号2019
            </Link>
          </li>
          <li
            onClick={() => {
              selectLink(26);
            }}
          >
            <Link to="/list" className={`${b[26] ? "active" : ""}`}>
              14.3委托单2019
            </Link>
          </li>



          {/* <li>
              <Link to="/product">新增参数</Link>
            </li>
            <li>
              <Link to="/makeorder">新增委托单</Link>
            </li> */}
        </ul>
      </div>

      <div className="box content">
        <Switch>
          <Route path="/about">
            <About />
          </Route>
          <Route path="/select">
            <App3 />
          </Route>
          <Route path="/list">
            <App />
          </Route>
          <Route path="/product">
            <ProductApp />
          </Route>
          <Route path="/makeorder">
            <MakeOrder />
          </Route>
          <Route path="/samplecode">
            <SampleCode />
          </Route>
          <Route path="/argument">
            <Argument />
          </Route>
          <Route path="/argumentpkg">
            <ArgumentPkg />
          </Route>

          <Route path="/argumentpkgedite">
            <ArgumentPkgNameJh />
          </Route>
          <Route path="/standardlink">
            <StandardLink />
          </Route>
          <Route path="/standardadd">
            <StandardAdd />
          </Route>
          <Route path="/sample/:id">
            <SampleEdit />
          </Route>
          <Route path="/samplejh/:id">
            <SampleEditeJh />
          </Route>
          <Route path="/samplelist">
            <SampleList />
          </Route>
          <Route path="/samplelist2">
            <SampleList2 />
          </Route>
          <Route path="/samplelist3">
            <SampleList3 />
          </Route>
          <Route exact path="/customer">
            <CustomerForm />
          </Route>
          <Route path="/customerselect">
            <CustomerSelect />
          </Route>
          <Route path="/customerlist">
            <CustomerList />
          </Route>
          <Route path="/sampledetail/:id">
            <SampleDetail />
          </Route>

          <Route path="/sendreport/:id">
            <SendReportAdd />
          </Route>

          <Route path="/exactinfomodel">
            <ExtraInfoModel />
          </Route>

          <Route path="/argumentlink">
            <ArgumentLinkStandard />
          </Route>

          <Route path="/pricever">
            <PriceVer />
          </Route>

          <Route path="/samplecodefind">
            <SampleCodeFind />
          </Route>

          <Route path="/sampleblank">
            <SampleBlank />
          </Route>

          <Route path="/sendreportlist">
            <SendReportList />
          </Route>

          <Route path="/projectname">
            <ProjectName />
          </Route>

          <Route path="/analysis">
            <AnalysisReport />
          </Route>

          <Route exact path="/">
            <SampleListFilterByReport />
          </Route>

          <Route path="/sampleupdatesamplecode/:id">
            <SampleUpdateSampleCode />
          </Route>

          <Route path="/standardlist">
            <StandardList />
          </Route>

          <Route path="/addstandard">
            <StandardAdd />
          </Route>

          <Route path="/standardselect">
            <StandardSelect />
          </Route>

          <Route path="/list2019bytrustname">
            <JholdSelect />
          </Route>

          <Route path="/sampleexport">
            <SampleExport />
          </Route>

          <Route path="/repaylist">
            <RepayList />
          </Route>
        </Switch>
      </div>
      <div className="box footer">
        <footer>
          <p style={{ textAlign: "center" }}>
            用户:{state.user.username} | {state.user.realname}
            {"\u00A9 2025"}
          </p>
        </footer>
      </div>
    </div>
  );
};

export default Home;
